export default {
  follow_up_greeting: "Hi {{name}},",
  follow_up_message:
    "Hi {{name}} \n\n{{itemName}} {{description}} {{title}} anda sudah saatnya: \n{{itemServices}} \nServis terakhir tanggal {{lastTransactionAt}} di: \n*{{vendorName}}* {{address}} {{city}} {{whatsapp}} \n\n Apakah anda ingin dibuatkan jadwal servis?",
  follow_up_multiple_message:
    "Hi {{name}} \n\nKami ingin sampaikan reminder servis berikut: \n{{itemServices}} \nServis terakhir tanggal {{lastTransactionAt}} di: \n*{{vendorName}}* {{address}} {{city}} {{whatsapp}} \n\n Apakah anda ingin dibuatkan jadwal servis?",
  follow_up_closing: "Apakah anda ingin dibuatkan jadwal servis?",
  last_service_at: "Servis terakhir tanggal {{lastTransactionAt}} di:",
  item_message: "{{itemName}} {{description}} {{title}} anda sudah saatnya:",
  multiple_item_message: "Kami ingin sampaikan reminder servis berikut:",
  disabled_follow_up_message:
    "Lengkapi nomor telepon pelanggan ini untuk follow up",
  disabled_call_message: "Lengkapi nomor telepon pelanggan ini untuk telepon",
  move_to_due: "Move to Due",
  lost_confirmation_message:
    "Stop follow up pelanggan ini dan pindahkan ke menu Lost. Reminder ini bisa dipindahkan kembali jika diinginkan.",
  move_to_due_confirmation_message:
    "Pindahkan reminder ini ke menu Due untuk di follow up lebih lanjut.",
  send_reminder: "Kirim Reminder",
  send: "Kirim",
  turn_off_reminder: "Yakin mau matikan reminder servise ini?",
};
